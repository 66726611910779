import { useEffect, useState } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import {
  AuthProvider,
  Client,
  getApolloClient,
  login,
  offlineLink,
  PrivateRoute,
  PublicRoute,
} from '@alpha/core'
import { message } from 'antd'

import SignInPage from './components/pages/SignInPage'
import RegisterUserPage from './components/pages/RegisterUserPage'

import ReportPage from './components/pages/ReportPage'
import ReportCountPage from './components/pages/ReportCountPage'
import ReportBodyPage from './components/pages/ReportBodyPage'
import ReportTestPage from './components/pages/ReportTestPage'
// import ReportTestComparisonPage from './components/pages/ReportTestComparisonPage'
import ReportTestRankPage from './components/pages/ReportTestRankPage'
import ReportTestRankTargetPage from './components/pages/ReportTestRankTargetPage'
import ReportScoreRadarChartPage from './components/pages/ReportScoreRadarChartPage'
import ReportQuestionnairePage from './components/pages/ReportQuestionnairePage'
import ReportCrossAnalysisPage from './components/pages/ReportCrossAnalysisPage'
import ReportAverageScoreGraphPage from './components/pages/ReportAverageScoreGraphPage'
import ReportTestRankTargetGraphPage from './components/pages/ReportTestRankTargetGraphPage'
import ReportCrossAnalysisGraphPage from './components/pages/ReportCrossAnalysisGraphPage'
// import ReportUploadListPage from './components/pages/ReportUploadListPage'
import ReportSchoolPerformanceListPage from './components/pages/ReportSchoolPerformanceListPage'
import ReportSchoolProgressListPage from './components/pages/ReportSchoolProgressListPage'
import { dashboardNavGraphItems } from './components/navGraph/DashboardNavGraphItem'

function App() {
  const [isOpen, setIsOpen] = useState(true)
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()
  useEffect(() => {
    login()
  }, [])

  useEffect(() => {
    window.addEventListener('offline', () => {
      message.warning('オフラインになりました。')
    })
    window.addEventListener('online', () => {
      offlineLink.sync().then(() => {
        message.success('オンラインになりました。変更を送信しました。')
      })
    })
  }, [])

  useEffect(() => {
    ;(async () => {
      const ac = await getApolloClient(Client.AdminClient)
      setClient(ac)
    })()
  }, [setClient])

  if (!client) return null

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Router>
          <Switch>
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/home"
              component={ReportPage}
              graphItem={dashboardNavGraphItems.find((i) => i.key === 'home')}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/register"
              component={RegisterUserPage}
              graphItem={null}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/count"
              component={ReportCountPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/count',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/body"
              component={ReportBodyPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/body',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/test"
              component={ReportTestPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/test',
              )}
            />
            {/* <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/test_comparison"
              component={ReportTestComparisonPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/test_comparison',
              )}
            /> */}
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/test_rank"
              component={ReportTestRankPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/test_rank',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/test_rank_target"
              component={ReportTestRankTargetPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/test_rank_target',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/score_chart"
              component={ReportScoreRadarChartPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/score_chart',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/questionnaire"
              component={ReportQuestionnairePage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/questionnaire',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/cross_analysis"
              component={ReportCrossAnalysisPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/cross_analysis',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/average_graph"
              component={ReportAverageScoreGraphPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/average_graph',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/test_rank_target_graph"
              component={ReportTestRankTargetGraphPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/test_rank_target_graph',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/cross_analysis_graph"
              component={ReportCrossAnalysisGraphPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/cross_analysis_graph',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/school_performance"
              component={ReportSchoolPerformanceListPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/school_performance',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/school_progress"
              component={ReportSchoolProgressListPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/school_progress',
              )}
            />
            {/* <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports/upload_list"
              component={ReportUploadListPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports/upload_list',
              )}
            /> */}
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/reports"
              component={ReportPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'reports',
              )}
            />
            <PublicRoute component={SignInPage} path="/" />
          </Switch>
        </Router>
      </AuthProvider>
    </ApolloProvider>
  )
}

export default App
